<template>
    <PopupWrpr @close="close">
        <div class="popup__window-content">
            <div class="popup__window-header">
                <h2>{{title}}</h2>
                <button type="button" class="popup__closer" @click="close">
                    <inline-svg :src="require('@/assets/img/close-icon.svg')"/>
                </button>
            </div>
            <div class="popup__window-body" style="padding-top: 10px;">
                <div class="table-wrapper">
                    <table class="table" :key="updateKey">
                        <thead>
                            <tr>
                                <th v-for="item in thArray" :key="item">
                                    {{ item }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="!multiple" >
                                <tr
                                    v-for="d in filteredRows"
                                    :key="d.id" @click="selectPaddock(d.id)" 
                                    :class="{ 'active': submitPaddock === d.id  }"
                                >
                                    <td>{{ d.basic_info.name }}</td>
                                    <td style="text-transform: capitalize;">{{ d.type }}</td>
                                    <td>{{ d.basic_info.total_area }}</td>
                                    <td>{{ d.basic_info.land_use || "-" }}</td>
                                    <td>{{ d.basic_info.crop_type || d.basic_info.plots_config?.crop || '-' }}</td>
                                    <td>{{ d.basic_info.colour_on_map }}</td>
                                </tr>
                            </template>
                            <template v-for="(d, dIndex) in filteredRows" v-else>
                                <!-- @click="selectPaddock(d.id)" -->
                                <tr
                                    :key="d.id"
                                    :class="{'active': submitPaddock.includes(d.id)}"
                                >
                                    <td>
                                        <div class="flex items-center">
                                            <a 
                                                @click.self="() => { openLandRegistryDrop[dIndex] = !openLandRegistryDrop[dIndex]; updateKey++; }"
                                                style="display: flex;min-width: 24px;margin-right: 10px;"
                                                v-if="showLandRegistry"
                                            >
                                                <img 
                                                    src="@/assets/img/Arrow-down.svg" style="pointer-events: none;" 
                                                    class="transition_03" :class="{ 'rotate_180':openLandRegistryDrop[dIndex] }"
                                                >
                                            </a>
                                            <label class="module__check">
                                                <input type="checkbox" name="category" :value="d.id" v-model="submitPaddock" @change="relatedPaddockChange(d)">
                                                <span class="check"></span>
                                                <span class="text">{{ d.basic_info.name }}</span>
                                            </label>
                                        </div>
                                    </td>
                                    <td style="text-transform: capitalize;">{{ d.type }}</td>
                                    <td>{{ d.basic_info.total_area }}</td>
                                    <td>{{ d.basic_info.land_use || "-" }}</td>
                                    <td>{{ d.basic_info.crop_type || d.basic_info.plots_config?.crop || '-' }}</td>
                                    <td>{{ d.basic_info.colour_on_map }}</td>
                                </tr>
                                <template v-if="showLandRegistry && d.land_register && d.land_register.length && openLandRegistryDrop[dIndex]">
                                    <tr :key="d.id+'-field-land-inner-register-th'">
                                        <th class="under_tr_th">#</th>
                                        <th class="under_tr_th" v-for="(columnName, index) in landRegistryThArray" :key="index + '-cadastral-head'">
                                            <div class="table-filter">
                                                <span>{{ columnName }}</span>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr v-for="(row, rowIndex) in d.land_register" :key="rowIndex+'-field-land-inner-register'" class="under_tr_tr_td">
                                        <td v-for="(columnData, columnIndex) in row" :key="columnIndex + '-cadastral-row'">
                                            <label class="module__check" v-if="columnIndex === 0">
                                                <input type="checkbox" name="category" :value="`${d.id}-landregister-index-${rowIndex}`" v-model="submitPaddock" @change="updateKey++">
                                                <span class="check"></span>
                                                <span class="text">{{ columnData }}</span>
                                            </label>
                                            <template v-else>
                                                {{ columnData }}
                                            </template>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                            <tr v-if="!filteredRows.length">
                                <td colspan="6">
                                    {{ $translate('list-empty') }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <PopupPagination v-model="filteredRows" :items="rawSource" />
            </div>
            <div class="popup__window-actions">
                <button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="close">
                    <span>{{ $translate('cancel') }}</span>
                </button>
                <button type="button" class="btn btn-primary btn-standart-2" @click="submit" :class="{processing}">
                    <span>{{ $translate('submit') }}</span>
                </button>
            </div>
        </div>
    </PopupWrpr>
</template>

<script>
import Base from "@/components/base";
import { mixTables } from "@/mixins";
import PopupWrpr from './PopupWrpr';

export default {
    name: "ChangePaddockBuildingPopup",
    components: {
        ...Base, 
        PopupWrpr,
    },
    mixins:[mixTables],
    props: ["paddocks", "title", "processing", "multiple", "value", "showLandRegistry"],
    data() {
        return {
            source: "paddocks",
            submitPaddock: null,
            thArray: [
                "Name",
                "Type",
                "Total Area",
                "Land Use",
                "Crop Type",
                "Colour on Map",
            ],
            landRegistryThArray: [
                'Country',
                'City',
                'Sheet land registry',
                'Particle land registry',
                'Area land registry'
            ],
            openLandRegistryDrop: [],
            updateKey: 0,
        };
    },
    computed: {
        sourceData(){
            return this.paddocks || []
        }
    },
    methods: {
        close() {
            this.$emit("close");
        },
        submit() {
            this.$emit("submit", this.submitPaddock);
        },
        selectPaddock(id){
            if(this.multiple){
                if(this.submitPaddock.includes(id)){
                    this.submitPaddock.splice(this.submitPaddock.findIndex(item => item === id), 1);
                } else {
                    this.submitPaddock.push(id);
                }
            } else {
                this.submitPaddock === id ? this.submitPaddock = null : this.submitPaddock = id; 
            }
        },
        relatedPaddockChange(paddock){
            if(paddock && paddock.land_register){
                for (let index = 0; index < paddock.land_register.length; index++) {
                    if(this.submitPaddock.includes(`${paddock.id}-landregister-index-${index}`)) {
                        this.submitPaddock.splice(this.submitPaddock.findIndex(item => item === `${paddock.id}-landregister-index-${index}`), 1);
                    }
                }
            }

            this.updateKey++;
        }
    },
    created(){
        if(this.multiple) this.submitPaddock = [];
        if(this.value) this.submitPaddock = JSON.parse(JSON.stringify(this.value));
    },
    mounted(){
        for (let index = 0; index < this.filteredRows.length; index++) {
            this.openLandRegistryDrop.push(false)
        }

        if(this.value){
            this.value.forEach(element => {
                if(element.includes('-landregister-index-')){
                    for (let index = 0; index < this.openLandRegistryDrop.length; index++) {
                        this.openLandRegistryDrop[index] = true;
                        this.updateKey++;
                    }
                }
            });
        }
    }
};
</script>